<template>
  <div class="mt-4">
    <form @submit.prevent="updateApplication">
      <div class="">
        <div class="container">
          <table id="app-details-table" class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>Last Name</td>
                <td>
                  <input
                    v-model="app.lastname"
                    type="text"
                    class="form-control text-uppercase"
                    required
                    :disabled="!editMode"
                  />
                </td>
              </tr>

              <tr>
                <td>First Name</td>
                <td>
                  <input
                    v-model="app.firstname"
                    type="text"
                    class="form-control text-uppercase"
                    required
                    :disabled="!editMode"
                  />
                </td>
              </tr>

              <tr>
                <td>Middle Name</td>
                <td>
                  <input
                    v-model="app.middlename"
                    type="text"
                    class="form-control text-uppercase"
                    required
                    :disabled="!editMode"
                  />
                </td>
              </tr>

              <tr>
                <td>Student Type</td>
                <td>
                  <select
                    name=""
                    class="form-control"
                    v-model="app.student_type"
                    id=""
                    :disabled="!editMode"
                  >
                    <option value="GR">GR</option>
                    <option value="UE">UE</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td>Purpose</td>
                <td>
                  <select
                    v-model="app.purpose"
                    class="form-control"
                    id=""
                    :disabled="!editMode"
                    required
                  >
                    <option value="" selected hidden disabled
                      >Select if Local or Abroad</option
                    >
                    <option value="Local">Local</option>
                    <option value="Abroad">Abroad</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td>Sex</td>
                <td>
                  <select
                    v-model="app.sex"
                    name=""
                    class="form-control"
                    id=""
                    :disabled="!editMode"
                    required
                  >
                    <option hidden selected disabled>Select sex</option>
                    <option value="" selected hidden disabled
                      >Male or Female</option
                    >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td>Date of Birth</td>
                <td>
                  <input
                    v-model="app.date_of_birth"
                    type="date"
                    class="form-control"
                    placeholder="Enter your age"
                    required
                    :disabled="!editMode"
                  />
                </td>
              </tr>

              <tr>
                <td>Email</td>
                <td>
                  <input
                    v-model="app.email"
                    type="email"
                    class="form-control"
                    placeholder="example@gmail.com"
                    :disabled="!editMode"
                  />
                </td>
              </tr>

              <tr>
                <td>Contact No</td>
                <td>
                  <input
                    v-model="app.contact_no"
                    type="text"
                    class="form-control"
                    placeholder="ex. 0936 425 3251"
                    :disabled="!editMode"
                    required
                  />
                </td>
              </tr>

              <tr>
                <td>Certified True Copy</td>
                <td>
                  <input
                    v-model="app.ctc_qty"
                    type="number"
                    class="form-control"
                    disabled
                  />
                </td>
              </tr>

              <tr>
                <td>Current remarks</td>
                <td>
                  {{ app.remarks }}
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="editable" class="">
            <button
              type="submit"
              class="btn"
              :class="{
                'btn-outline-secondary text-dark': !editMode,
                'btn-success': editMode,
              }"
              @click.prevent="saveOrEdit"
              :disabled="updating"
            >
              <i class="bi bi-pencil-square mr-1"></i>
              {{ editMode ? 'Save changes' : 'Edit' }}
            </button>

            <span
              class="spinner-border spinner-border-sm mx-3"
              v-if="updating"
            ></span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AppDetails',
  props: {
    app: {
      required: true,
      type: Object,
    },
    editable: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      editMode: false,
      updating: false,
    };
  },
  methods: {
    saveChanges() {
      this.updating = !this.updating;

      axios
        .post('api/cav-applications/edit-details', this.app)
        .then((res) => {
          this.updating = !this.updating;
          this.$emit('updateTable');
          this.toggleEditing();
        })
        .catch((err) => {
          toastr.error('Unable to update application details.', 'Error');
          this.updating = !this.updating;
          this.toggleEditing();
        });
    },

    saveOrEdit() {
      this.editMode ? this.saveChanges() : (this.editMode = !this.editMode);
    },

    toggleEditing() {
      this.editMode = !this.editMode;
    },
  },
};
</script>

<style scoped>
#app-details-table td {
  vertical-align: middle !important;
}
</style>
