<template>
  <div class="">
    <div class="row mb-4">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary text-dark" @click="clear">
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            @change="changeInputType"
            v-model="column"
          >
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="created_at">Date Applied</option>
            <option value="abbrev">Institution</option>
          </select>
          <input
            id="search-input"
            type="search"
            class="form-control"
            placeholder="Search"
            v-model="searchValue"
          />
        </div>
      </div>

      <div class="col-auto px-2">
        <button
          @click="
            getForRelease({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: true },
            })
          "
          class="btn btn-outline-secondary text-dark"
        >
          <i class="bi bi-arrow-repeat mr-1"></i> Reload
          <div
            v-if="reloadLoader"
            class="spinner-border spinner-border-sm mx-1"
            role="status"
          >
            <span class="invisible">Loading...</span>
          </div>
        </button>
      </div>
      <div class="col-auto px-2">
        <button
          @click="openModal"
          class="btn btn-outline-secondary text-dark"
          :disabled="!selectedApps.length"
        >
          <i class="bi bi-check2-all mr-1"></i> Release
          <span class="badge badge-secondary" v-show="selectedApps.length">{{
            selectedApps.length
          }}</span>
        </button>
      </div>
      <div class="col-auto px-2">
        <ActionsDropdown
          :selectedApps="selectedApps"
          @updateTable="
            getForRelease({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: false },
            })
          "
          :statusId="5"
        ></ActionsDropdown>
      </div>
    </div>

    <table
      id="apps-for-release-table"
      class="table table-sm table-bordered table-hover"
    >
      <thead>
        <th>
          <input
            type="checkbox"
            class="large-checkbox"
            v-model="checked"
            @change="checked ? selectAll() : unselectAll()"
          />
        </th>
        <th>APP NO</th>
        <th>NAME</th>
        <th>SECPA NO</th>
        <th>TYPE</th>
        <th>HEI</th>
        <th>PROGRAM</th>
        <th>PURPOSE</th>
        <th>DATE APPLIED</th>
        <th>CTC</th>
        <th>STATUS</th>
      </thead>

      <tbody class="text-uppercase">
        <tr v-show="tableLoader" class="text-center">
          <td colspan="16">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            Loading...
          </td>
        </tr>
        <tr v-if="!filteredApps.length && !tableLoader" class="text-center">
          <td colspan="16">No results found.</td>
        </tr>
        <tr
          v-for="app in filteredApps"
          :key="app.id"
          @click.stop="openAppDetailsModal(app)"
          :class="{
            'table-primary font-weight-bold': selectedApps.some(
              (obj) => obj.id == app.id
            ),
          }"
        >
          <td>
            <input
              class="large-checkbox"
              type="checkbox"
              :value="app"
              v-model="selectedApps"
              @click.stop
            />
          </td>
          <td>{{ app.id }}</td>
          <td class="">
            {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
          </td>
          <td>{{ app.secpa_no }}</td>
          <td>{{ app.student_type }}</td>
          <td>{{ app.abbrev }}</td>
          <td width="30%">{{ app.program_name }}</td>
          <td>{{ app.purpose }}</td>
          <td>{{ app.created_at | dateTime }}</td>
          <td class="text-right">{{ app.ctc_qty }}</td>

          <td>
            <span class="badge badge-success">{{ app.status }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <AppDetailsModal ref="AppDetailsModal" :editable="false"></AppDetailsModal>

    <div class="modal fade" id="add-release-details-modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <form @submit.prevent="addReleaseDetails">
            <div class="modal-body">
              <table class="table table-sm table-bordered">
                <thead>
                  <th>App No</th>
                  <th>Name</th>
                  <th>Institution</th>
                </thead>
                <tbody>
                  <tr v-for="app in selectedApps" :key="app.id">
                    <td>{{ app.id }}</td>
                    <td>
                      <span class="text-uppercase">
                        {{ app.firstname }}
                        {{ app.middlename }} {{ app.lastname }}
                      </span>
                    </td>
                    <td>{{ app.abbrev }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="form-group">
                <label for="">Received by</label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  v-model="received_by"
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button id="release-btn" type="submit" class="btn btn-success">
                Release
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailsModal from './AppDetailsModal.vue';
import ActionsDropdown from './ActionsDropdown.vue';

export default {
  name: 'ForRelease',
  components: {
    AppDetailsModal,
    ActionsDropdown,
  },
  data() {
    return {
      column: 'lastname',
      searchValue: '',
      received_by: '',
      file: '',
      selectedApps: [],
      checked: false,
      apps: [],
      currentID: '',
      tableLoader: false,
      reloadLoader: false,
    };
  },
  methods: {
    openModal() {
      $('#add-release-details-modal').modal('show');
    },
    changeInputType() {
      this.searchValue = '';
      let input = document.getElementById('search-input');
      this.column == 'created_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
    clear() {
      this.column = '';
      this.searchValue = '';
    },
    addReleaseDetails() {
      let btn = document.getElementById('release-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Releasing...`;
      btn.disabled = true;

      axios
        .post('/api/cav-applications/release-applications', {
          received_by: this.received_by,
          applications: this.selectedApps,
        })
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          btn.innerHTML = 'Release';
          btn.disabled = false;
          $('#add-release-details-modal').modal('hide');
          this.getForRelease({
            tableLoader: { enabled: false },
            reloadLoader: { enabled: false },
          });
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Error');
          btn.innerHTML = 'Release';
          btn.disabled = false;
        });
    },
    getForRelease(config) {
      this.tableLoader = config.tableLoader.enabled;
      this.reloadLoader = config.reloadLoader.enabled;
      axios
        .get('/api/cav-applications/filter/5')
        .then((res) => {
          this.apps = res.data;
          this.tableLoader = false;
          this.reloadLoader = false;
          this.selectedApps = [];
        })
        .catch((err) => {
          this.tableLoader = false;
          this.reloadLoader = false;
        });
    },

    openAppDetailsModal(data) {
      this.$refs.AppDetailsModal.openModal(data);
    },

    selectAll() {
      this.selectedApps = [];
      this.filteredApps.forEach((student) => {
        this.selectedApps.push(student);
      });
    },

    unselectAll() {
      this.selectedApps = [];
    },
  },

  computed: {
    filteredApps() {
      let temp = this.apps;

      if (this.searchValue != '') {
        temp = temp.filter((cav) => {
          return cav[this.column]
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getForRelease({
      tableLoader: { enabled: true },
      reloadLoader: { enabled: false },
    });
    this.$emit('getStats');
  },
};
</script>

<style scoped>
#apps-for-release-table tr {
  cursor: pointer !important;
}
</style>
