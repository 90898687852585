<template>
  <div>
    <div class="modal" data-backdrop="static" id="app-details-modal">
      <div class="modal-dialog" style="max-width: 600px;">
        <div class="modal-content">
          <form @submit.prevent="updateApplication">
            <div class="modal-body">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-uppercase mb-0 font-weight-bold">
                      {{ app.firstname }} {{ app.middlename }}
                      {{ app.lastname }}
                    </h5>
                    <span class="app-text"
                      >App No: <strong>{{ app.id }}</strong>
                    </span>
                    <p class="mb-0">{{ app.abbrev }} | {{ app.hei_name }}</p>
                    <p>{{ app.program_name }}</p>
                  </div>
                  <div class="">
                    <i class="bi bi-person-circle" style="font-size: 50px"></i>
                  </div>
                </div>

                <nav
                  id="app-details-nav"
                  class="nav nav-pills nav-justified mt-3"
                >
                  <a
                    id="AppDetails"
                    class="nav-item nav-link "
                    @click.prevent="tabActive('AppDetails')"
                    href="#"
                    >App Details</a
                  >
                  <a
                    id="PaymentDetails"
                    class="nav-item nav-link"
                    @click.prevent="tabActive('PaymentDetails')"
                    href="#"
                    >Payment</a
                  >
                  <a
                    id="Files"
                    class="nav-item nav-link"
                    @click.prevent="tabActive('Files')"
                    href="#"
                    >Files</a
                  >
                  <a
                    id="StatusLog"
                    class="nav-item nav-link active"
                    @click.prevent="tabActive('StatusLog')"
                    href="#"
                    >Status Log</a
                  >
                </nav>

                <div>
                  <component
                    v-bind:is="currentTab"
                    :app="app"
                    :editable="editable"
                    @updateTable="updateTable"
                  ></component>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusLog from './StatusLog.vue';
import Files from './Files.vue';
import AppDetails from './AppDetails.vue';
import PaymentDetails from './PaymentDetails.vue';

export default {
  name: 'AppDetailsModal',
  components: {
    StatusLog,
    Files,
    AppDetails,
    PaymentDetails,
  },
  props: {
    editable: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      prefix: 'https://drive.google.com/file/d/',
      currentTab: 'AppDetails',
      app: new Form({
        id: '',
        lastname: '',
        firstname: '',
        middlename: '',
        email: '',
        sex: '',
        date_of_birth: '',
        contact_no: '',
        student_type: '',
        sector: '',
        purpose: '',
        payment_id: '',
        ctc_qty: '',
        file: '',
        school_graduated: '',
        created_at: '',
        status_id: '',
        remarks: '',
        updated_at: '',
        updated_by: '',
        receipt_no: '',
        abbrev: '',
        hei_name: '',
        completed_at: '',
        program_name: '',
      }),
    };
  },
  methods: {
    openModal(app) {
      this.tabActive('AppDetails');
      $('#app-details-modal').modal('show');
      this.app.fill(app);
    },

    tabActive(element) {
      this.currentTab = element;
      $('#app-details-nav .nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },

    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },

    updateTable() {
      this.$emit('updateTable');
    },
  },
};
</script>

<style scoped>
a.nav-item.nav-link {
  border-bottom: 2px solid #ccc;
  border-radius: 0px;
  color: #3c4b64;
}

.nav-item.nav-link.active {
  background-color: white;
  color: blue;
  border-bottom: 2px solid blue;
  border-radius: 0px;
  font-weight: 550;
}
</style>
