<template>
  <div class="">
    <div class="row mb-4">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary text-dark" @click="clear">
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            @change="changeInputType"
            v-model="column"
          >
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="created_at">Date Applied</option>
            <option value="abbrev">Institution</option>
          </select>
          <input
            id="search-input"
            type="search"
            class="form-control"
            placeholder="Search"
            v-model="searchValue"
          />
        </div>
      </div>

      <div class="col-auto px-2">
        <button
          @click="
            getForProcess({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: true },
            })
          "
          class="btn btn-outline-secondary text-dark"
        >
          <i class="bi bi-arrow-repeat mr-1"></i> Reload
          <div
            v-if="reloadLoader"
            class="spinner-border spinner-border-sm mx-1"
            role="status"
          >
            <span class="invisible">Loading...</span>
          </div>
        </button>
      </div>

      <div class="col-auto px-2">
        <ActionsDropdown
          :selectedApps="selectedApps"
          @updateTable="
            getForProcess({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: false },
            })
          "
          :statusId="4"
        ></ActionsDropdown>
      </div>
    </div>
    <table
      id="apps-for-process-table"
      class="table table-sm table-bordered table-hover"
    >
      <thead>
        <th>
          <input
            type="checkbox"
            v-model="checked"
            class="large-checkbox"
            @change="checked ? selectAll() : unselectAll()"
          />
        </th>
        <th>APP NO</th>
        <th>NAME</th>
        <th>SECPA NO</th>
        <th>TYPE</th>
        <th>HEI</th>
        <th>PROGRAM</th>
        <th>PURPOSE</th>
        <th>DATE APPLIED</th>
        <th>CTC</th>

        <th>STATUS</th>
      </thead>

      <tbody class="text-uppercase">
        <tr v-show="tableLoader" class="text-center">
          <td colspan="15">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            Loading...
          </td>
        </tr>
        <tr v-if="!filteredApps.length && !tableLoader" class="text-center">
          <td colspan="15">No results found.</td>
        </tr>
        <tr
          v-for="app in filteredApps"
          :key="app.id"
          @click.stop="openAppDetailsModal(app)"
          :class="{
            'table-primary font-weight-bold': selectedApps.some(
              (obj) => obj.id == app.id
            ),
          }"
        >
          <td>
            <input
              class="large-checkbox"
              type="checkbox"
              :value="app"
              v-model="selectedApps"
              @click.stop
            />
          </td>
          <td>
            <OverdueIndicator :created_at="app.created_at"> </OverdueIndicator>
            {{ app.id }}
          </td>

          <td>
            <a href="#" @click.stop="openEditSpecialOrderModal(app)" class="">
              {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
            </a>
          </td>
          <td>
            <a href="#" @click.stop="getDataForPrint(app.cav_id)">{{
              app.secpa_no
            }}</a>
          </td>
          <td>{{ app.student_type }}</td>
          <td>{{ app.abbrev }}</td>
          <td width="30%">{{ app.program_name }}</td>
          <td>{{ app.purpose }}</td>
          <td>{{ app.created_at | dateTime }}</td>
          <td class="text-right">{{ app.ctc_qty }}</td>

          <td>
            <span class="badge badge-info">{{ app.status }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <EditSpecialOrderModal ref="EditSpecialOrderModal"> </EditSpecialOrderModal>

    <AppDetailsModal
      ref="AppDetailsModal"
      @updateTable="
        getForProcess({
          tableLoader: { enabled: false },
          reloadLoader: { enabled: false },
        })
      "
      :editable="true"
    ></AppDetailsModal>

    <div id="cav-print" v-show="false">
      <div
        id="main"
        style="position:fixed; top: 275px; left:90px; right: 90px;"
      >
        <p style="font-family: Arial; font-size: 18px;">
          {{ cav.created_at | standardDate }}
        </p>
        <b style="font-family: Arial; font-size: 18px; font-weight:bold"
          >CAV (CHED-CARAGA) NO. {{ cav.control_no }}</b
        >
        <p style="font-family: Arial; font-size: 18px;" class="mb-4">
          Series of {{ new Date().getFullYear() }}
        </p>

        <h5
          class="text-center mb-4"
          style="font-family: Arial; font-size: 28px; font-weight:bold;"
        >
          CERTIFICATION, AUTHENTICATION AND VERIFICATION
        </h5>
        <div style="font-family: Arial; font-size: 18px;">
          <p>To Whom it May Concern:</p>
          <p
            style="text-indent: 50px; text-align:justify; text-justify: inter-word;"
          >
            This is to certify that based on the confirmation forwarded by
            <span v-if="cav.type == 'LUC'">LUC</span> <span v-else>HEI</span>,
            the following information are true and correct:
          </p>
        </div>

        <!-- STUDENT INFO -->
        <div
          id="desc"
          style="font-family: Arial; font-size: 18px;"
          class="mt-3"
        >
          <div id="labels">
            <p class="mb-0">
              Name of Student
            </p>
            <p class="mb-0">Degree</p>
            <p v-if="cav.major" class="mb-0" style="color:transparent;">
              Major
            </p>
            <p class="mb-0">Date Started</p>
            <p class="mb-0">Date Ended</p>
            <p v-if="cav.student_type === 'GR'" class="mb-0">
              Date of Graduation
            </p>
            <p v-if="cav.student_type === 'GR'" class="mb-0">
              Special Order No.
            </p>
            <p v-if="cav.student_type === 'UE'" class="mb-0">Units Earned</p>
            <p class="mb-0">Mode of Study</p>
            <p class="mb-0">Name of Institution</p>
            <p
              v-if="cav.hei_code == '10026'"
              class="mb-0"
              style="color:transparent;"
            >
              Spacer
            </p>
            <!-- spacer for DJEMFCST -->
            <p v-if="cav.former_name" class="mb-0" style="color:transparent;">
              Formerly
            </p>
            <p class="mb-0">Address</p>
          </div>

          <div
            id="separators"
            style="font-family: Arial; font-size: 18px; position:fixed; top: 565px; left: 250px;"
            class=""
          >
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p v-if="cav.major" class="mb-0" style="color:transparent;">:</p>
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p v-if="cav.student_type == 'GR'" class="mb-0">:</p>
            <p v-if="cav.student_type == 'GR'" class="mb-0">:</p>
            <p v-if="cav.student_type == 'UE'" class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p
              class="mb-0"
              v-if="cav.hei_code == '10026'"
              style="color:transparent;"
            >
              :
            </p>
            <p
              v-if="cav.former_name != null"
              class="mb-0"
              style="color:transparent;"
            >
              :
            </p>
            <p class="mb-0">:</p>
          </div>

          <div
            id="values"
            style=" font-family: Arial; font-size: 18px; position:fixed; top: 565px; left: 280px;"
            class=""
          >
            <p class="mb-0 text-uppercase">
              <b>{{ cav.firstname }} {{ cav.middlename }} {{ cav.lastname }}</b>
            </p>
            <p class="mb-0 text-uppercase">
              <b>{{ cav.trimmedProgName }}</b>
            </p>
            <p v-if="cav.major" class="mb-0 text-uppercase">
              <b>{{ cav.major }}</b>
            </p>
            <p class="mb-0 text-uppercase">
              <b>{{ cav.semEnrolled }} AY {{ cav.acadYear }}</b>
            </p>
            <p class="mb-0 text-uppercase">
              <b>{{ cav.semStarted }} AY {{ cav.semYear }}</b>
            </p>
            <p v-if="cav.gradDate" class="mb-0 text-uppercase">
              <b>{{ cav.gradDate }}</b>
            </p>

            <p v-else class="mb-0 text-uppercase">
              <b>{{ cav.units_earned }}</b>
            </p>
            <p v-if="cav.student_type == 'GR'" class="mb-0 text-uppercase">
              <b
                >{{ cav.soLevel }} {{ cav.soRegion }}
                <span v-show="cav.soSeries">NO.</span> {{ cav.soNumber }}
                <span v-show="cav.soSequence">-</span>
                {{ cav.soSequence }} <span v-show="cav.soSeries">S.</span>
                {{ cav.soSeries }}</b
              >
            </p>

            <p class="mb-0 text-uppercase">
              <b>{{ cav.study_mode }}</b>
            </p>
            <p class="mb-0 text-uppercase">
              <b>{{ cav.name }}</b>
            </p>
            <p v-if="cav.former_name" class="mb-0 text-uppercase">
              <b class="font-italic">(Formerly: {{ cav.former_name }})</b>
            </p>

            <p class="mb-0 text-uppercase">
              <b>{{ cav.schoolAddress }}</b>
            </p>
          </div>
        </div>
        <!-- END - STUDENT INFO -->

        <div
          class="mt-4"
          style="font-family: Arial; font-size: 18px; text-align:justify; text-justify: inter-word;"
        >
          <p style="text-indent: 50px;">
            This is to further certify that the above institution is a duly
            authorized
            <span v-if="cav.type == 'Private HEIs'"
              >private higher education institution</span
            >
            <span v-else>public higher education institution</span> (HEI)
            <span v-if="cav.closed_at !== null"
              >which ceased its operation effective {{ cav.closed_at }} </span
            >and the entries in the Transcript of Records and
            <span v-if="cav.student_type == 'GR'">Diploma</span>
            <span v-else>Certification</span> are authentic and the signatures
            appearing therein are those of the
            <span v-if="cav.type == 'Private HEIs'">HEI President</span
            ><span v-else>LUC President</span> and other authorities.
          </p>
          <p style="text-indent: 50px;">
            This certification must not be honored if the copies of the
            student's Transcript of Records and
            <span v-if="cav.student_type == 'GR'">Diploma</span>
            <span v-else>Certification</span> presented are not duly
            authenticated/certified by the
            <span v-if="cav.type == 'Private HEIs'">HEI</span
            ><span v-else>LUC</span> Registrar.
          </p>
          <p style="text-indent: 50px;">
            Issued upon the request of
            <u
              ><b class="font-italic text-uppercase"
                >{{ cav.firstname }} {{ cav.middlename }} {{ cav.lastname }}</b
              ></u
            >
            <span
              v-if="cav.purpose == 'Local' && cav.reason != 'Board Examination'"
            >
              for whatever legal purpose it may serve.</span
            >
            <span
              v-if="cav.purpose == 'Local' && cav.reason == 'Board Examination'"
            >
              for board examination purpose.</span
            >

            <span v-if="cav.purpose == 'Abroad'">
              for authentication of the Department of Foreign Affairs
              (DFA).</span
            >
          </p>
        </div>

        <div class="mt-4">
          <div class="row">
            <div class="col" style="font-family: Arial; font-size: 18px;">
              <p class="mb-5"></p>
              <p class="mb-0" style="font-family: Arial; font-size: 18px;">
                <b>NOT VALID WITHOUT CHED SEAL OR</b>
              </p>
              <p style="text-indent: 15px;">
                <b style="font-family: Arial; font-size: 18px;"
                  >WITH ERASURE OR ALTERATION</b
                >
              </p>
            </div>
            <div class="col" style="font-family: Arial; font-size: 18px;">
              <p class="mb-5 ">For the Commission:</p>

              <b
                ><u>{{ cav.trimmedSigned }}</u></b
              >
              <p>{{ cav.position }}</p>
            </div>
          </div>
        </div>

        <div class="mt-5" style="font-family: Arial; font-size: 14px;">
          <div class="d-flex justify-content-center">
            <div class="pr-2">
              <p class="mb-0 ">Application No</p>
              <p class="mb-0 ">Processed by</p>
              <p class="mb-0 ">Reviewed by</p>
              <p class="mb-0 ">CN/OR No.</p>
              <p class="mb-0 ">Date Issued</p>
              <p class="mb-0 ">Amount</p>
            </div>
            <div class="pr-2">
              <p class="mb-0">:</p>
              <p class="mb-0">:</p>
              <p class="mb-0">:</p>
              <p class="mb-0">:</p>
              <p class="mb-0">:</p>
              <p class="mb-0">:</p>
            </div>

            <div style="">
              <p class="mb-0">{{ cav.app_id }}</p>
              <p class="mb-0">{{ cav.processed_by }}</p>
              <p class="mb-0">{{ cav.reviewed_by }}</p>
              <p class="mb-0">{{ cav.receipt_no }}</p>
              <p class="mb-0">{{ cav.receipt_date | standardDate }}</p>
              <p class="mb-0">&#8369; 80.00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailsModal from './AppDetailsModal.vue';
import EditSpecialOrderModal from './EditSpecialOrderModal.vue';
import ActionsDropdown from './ActionsDropdown.vue';
import OverdueIndicator from './OverdueIndicator.vue';

export default {
  name: 'ForProcess',
  components: {
    AppDetailsModal,
    EditSpecialOrderModal,
    ActionsDropdown,
    OverdueIndicator,
  },
  data() {
    return {
      selectedApps: [],
      searchValue: '',
      file: '',
      apps: [],
      applications: [],
      currentID: '',
      tableLoader: false,
      reloadLoader: false,
      cav: '',
      column: 'lastname',
      checked: false,
    };
  },
  methods: {
    getForProcess(config) {
      this.tableLoader = config.tableLoader.enabled;
      this.reloadLoader = config.reloadLoader.enabled;

      axios
        .get('/api/cav-applications/filter/4')
        .then((res) => {
          this.apps = res.data;
          this.tableLoader = false;
          this.reloadLoader = false;
          this.selectedApps = [];
        })
        .catch((err) => {
          this.tableLoader = false;
          this.reloadLoader = false;
        });
    },
    print(cav) {
      this.cav = cav;
      let position = this.cav.signed_by.split(':');
      let program = this.cav.progName.split('-');
      this.cav.trimmedSigned = position[0];
      this.cav.position = position[1];
      this.cav.trimmedProgName = program[0];
      this.cav.major = program[1];
      setTimeout(() => {
        this.$htmlToPaper('cav-print');
      }, 1000);
    },
    changeInputType() {
      this.searchValue = '';
      let input = document.getElementById('search-input');
      this.column == 'created_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
    clear() {
      this.column = '';
      this.searchValue = '';
    },
    getDataForPrint(cavId) {
      axios
        .get('api/cavs/' + cavId)
        .then((res) => {
          this.print(res.data[0]);
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Error');
        });
    },

    openAppDetailsModal(data) {
      this.$refs.AppDetailsModal.openModal(data);
    },

    openEditSpecialOrderModal(app) {
      this.$refs.EditSpecialOrderModal.open(app);
    },

    selectAll() {
      this.selectedApps = [];
      this.filteredApps.forEach((student) => {
        this.selectedApps.push(student);
      });
    },

    unselectAll() {
      this.selectedApps = [];
    },
  },

  computed: {
    filteredApps() {
      let temp = this.apps;

      if (this.searchValue != '') {
        temp = temp.filter((cav) => {
          return cav[this.column]
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getForProcess({
      tableLoader: { enabled: true },
      reloadLoader: { enabled: false },
    });

    this.$emit('getStats');
  },
};
</script>

<style scoped>
#apps-for-process-table tr {
  cursor: pointer !important;
}
</style>
