<template>
  <div>
    <div class="modal fade" id="edit-special-order-modal">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-center" v-if="show">
              <div class="spinner-border spinner-border-sm my-auto mr-2"></div>
              <span class=""
                >Loading details from database, please wait...</span
              >
            </div>
            <div class="row" v-else>
              <div class="col-4 pr-0">
                <div class="card shadow-sm mb-0">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="">Name</label>
                      <h5 class="text-uppercase">
                        {{ app.firstname }} {{ app.middlename }}
                        {{ app.lastname }} ({{ app.student_type }})
                      </h5>
                      <p class="mb-0">
                        Application No: <b>{{ app.id }} </b> | SO ID:
                        <b>{{ form.id }}</b>
                      </p>
                    </div>

                    <div class="form-group">
                      <label for="">Institution</label>
                      <select
                        name=""
                        id=""
                        class="form-control text-uppercase"
                        v-model="form.schoolCode"
                        :disabled="!edit"
                      >
                        <option
                          v-for="hei in heis"
                          :key="hei.code"
                          :value="hei.code"
                          >{{ hei.abbrev }} | {{ hei.name }}</option
                        >
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="">Degree</label>
                      <p class="font-weight-bold">{{ form.progName }}</p>
                    </div>

                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label for="">Semester Started</label>
                          <select
                            name=""
                            id=""
                            class="form-control  "
                            v-model="form.semEnrolled"
                            :disabled="!edit"
                          >
                            <option value="" selected disabled hidden
                              >Select Semester</option
                            >
                            <option value="1ST SEMESTER">1ST SEMESTER</option>
                            <option value="2ND SEMESTER">2ND SEMESTER</option>
                            <option value="3RD SEMESTER">3RD SEMESTER</option>
                            <option value="SUMMER">SUMMER</option>
                            <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                            <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                            <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="">AY Started</label>
                          <select
                            name=""
                            id=""
                            class="form-control  "
                            v-model="form.acadYear"
                            :disabled="!edit"
                          >
                            <option value="2022-2023">2022-2023</option>
                            <option value="2021-2022">2021-2022</option>
                            <option value="2020-2021">2020-2021</option>
                            <option value="2019-2020">2019-2020</option>
                            <option value="2018-2019">2018-2019</option>
                            <option value="2017-2018">2017-2018</option>
                            <option value="2016-2017">2016-2017</option>
                            <option value="2015-2016">2015-2016</option>
                            <option value="2014-2015">2014-2015</option>
                            <option value="2013-2014">2013-2014</option>
                            <option value="2012-2013">2012-2013</option>
                            <option value="2011-2012">2011-2012</option>
                            <option value="2010-2011">2010-2011</option>
                            <option value="2009-2010">2009-2010</option>
                            <option value="2008-2009">2008-2009</option>
                            <option value="2007-2008">2007-2008</option>
                            <option value="2006-2007">2006-2007</option>
                            <option value="2005-2006">2005-2006</option>
                            <option value="2004-2005">2004-2005</option>
                            <option value="2003-2004">2003-2004</option>
                            <option value="2002-2003">2002-2003</option>
                            <option value="2001-2002">2001-2002</option>
                            <option value="2000-2001">2000-2001</option>
                            <option value="1999-2000">1999-2000</option>
                            <option value="1998-1999">1998-1999</option>
                            <option value="1997-1998">1997-1998</option>
                            <option value="1996-1997">1996-1997</option>
                            <option value="1995-1996">1995-1996</option>
                            <option value="1994-1995">1994-1995</option>
                            <option value="1993-1994">1993-1994</option>
                            <option value="1992-1993">1992-1993</option>
                            <option value="1991-1992">1991-1992</option>
                            <option value="1990-1991">1990-1991</option>
                            <option value="1989-1990">1989-1990</option>
                            <option value="1988-1989">1988-1989</option>
                            <option value="1987-1988">1987-1988</option>
                            <option value="1986-1987">1986-1987</option>
                            <option value="1985-1986">1985-1986</option>
                            <option value="1984-1985">1984-1985</option>
                            <option value="1983-1984">1983-1984</option>
                            <option value="1982-1983">1982-1983</option>
                            <option value="1981-1982">1981-1982</option>
                            <option value="1980-1981">1980-1981</option>
                            <option value="1979-1980">1979-1980</option>
                            <option value="1978-1979">1978-1979</option>
                            <option value="1977-1978">1977-1978</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label for="">Semester Ended</label>
                          <select
                            name=""
                            id=""
                            class="form-control f "
                            v-model="form.semStarted"
                            :disabled="!edit"
                          >
                            <option value="" selected disabled hidden
                              >Select Semester</option
                            >
                            <option value="1ST SEMESTER">1ST SEMESTER</option>
                            <option value="2ND SEMESTER">2ND SEMESTER</option>
                            <option value="3RD SEMESTER">3RD SEMESTER</option>
                            <option value="SUMMER">SUMMER</option>
                            <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                            <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                            <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="">AY Ended</label>
                          <select
                            name=""
                            id=""
                            class="form-control "
                            v-model="form.semYear"
                            :disabled="!edit"
                          >
                            <option value="2022-2023">2022-2023</option>
                            <option value="2021-2022">2021-2022</option>
                            <option value="2020-2021">2020-2021</option>
                            <option value="2019-2020">2019-2020</option>
                            <option value="2018-2019">2018-2019</option>
                            <option value="2017-2018">2017-2018</option>
                            <option value="2016-2017">2016-2017</option>
                            <option value="2015-2016">2015-2016</option>
                            <option value="2014-2015">2014-2015</option>
                            <option value="2013-2014">2013-2014</option>
                            <option value="2012-2013">2012-2013</option>
                            <option value="2011-2012">2011-2012</option>
                            <option value="2010-2011">2010-2011</option>
                            <option value="2009-2010">2009-2010</option>
                            <option value="2008-2009">2008-2009</option>
                            <option value="2007-2008">2007-2008</option>
                            <option value="2006-2007">2006-2007</option>
                            <option value="2005-2006">2005-2006</option>
                            <option value="2004-2005">2004-2005</option>
                            <option value="2003-2004">2003-2004</option>
                            <option value="2002-2003">2002-2003</option>
                            <option value="2001-2002">2001-2002</option>
                            <option value="2000-2001">2000-2001</option>
                            <option value="1999-2000">1999-2000</option>
                            <option value="1998-1999">1998-1999</option>
                            <option value="1997-1998">1997-1998</option>
                            <option value="1996-1997">1996-1997</option>
                            <option value="1995-1996">1995-1996</option>
                            <option value="1994-1995">1994-1995</option>
                            <option value="1993-1994">1993-1994</option>
                            <option value="1992-1993">1992-1993</option>
                            <option value="1991-1992">1991-1992</option>
                            <option value="1990-1991">1990-1991</option>
                            <option value="1989-1990">1989-1990</option>
                            <option value="1988-1989">1988-1989</option>
                            <option value="1987-1988">1987-1988</option>
                            <option value="1986-1987">1986-1987</option>
                            <option value="1985-1986">1985-1986</option>
                            <option value="1984-1985">1984-1985</option>
                            <option value="1983-1984">1983-1984</option>
                            <option value="1982-1983">1982-1983</option>
                            <option value="1981-1982">1981-1982</option>
                            <option value="1980-1981">1980-1981</option>
                            <option value="1979-1980">1979-1980</option>
                            <option value="1978-1979">1978-1979</option>
                            <option value="1977-1978">1977-1978</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" v-show="app.student_type == 'GR'">
                      <label for="">Special Order</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.soLevel"
                          disabled
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.soRegion"
                          disabled
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.soNumber"
                          disabled
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.soSequence"
                          disabled
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.soSeries"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="form-group" v-show="app.student_type == 'GR'">
                      <label for="">Date of Graduation</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.gradDate"
                        disabled
                      />
                    </div>

                    <div class="mt-4">
                      <div class="float-right">
                        <button
                          class="btn btn-success"
                          @click="edit = true"
                          v-show="!edit && app.student_type == 'UE'"
                        >
                          <i class="bi bi-pencil mr-1"></i> Edit Details
                        </button>

                        <button
                          id="edit-unit-earner-btn"
                          class="btn btn-primary"
                          v-show="edit"
                          @click="save"
                        >
                          Save
                        </button>
                      </div>
                    </div>

                    <div>
                      <small class="form-text"
                        >Last updated by: {{ form.updateby }}</small
                      >
                      <small>Date: {{ form.updateon | standardDate }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div>
                  <iframe
                    :src="src"
                    width="100%"
                    height="660"
                    allow="autoplay"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      edit: false,
      programs: [],
      heis: [],
      soId: '',
      app: '',
      src: '',
      form: new Form({
        id: '',
        lastName: '',
        middleName: '',
        firstName: '',
        progName: '',
        schoolCode: '',
        semEnrolled: '',
        acadYear: '',
        semStarted: '',
        semYear: '',
        gender: '',
        path: '',
        filename: '',
        soLevel: '',
        soRegion: '',
        soNumber: '',
        soSequence: '',
        soSeries: '',
        gradDate: '',
        updateon: '',
        updateby: '',
      }),
    };
  },
  methods: {
    open(app) {
      $('#edit-special-order-modal').modal('show');
      this.app = app;

      this.getHeis();
      this.get(app.id);
      this.src = '';
      this.edit = false;
    },
    get(cavAppId) {
      this.show = true;

      axios
        .get('api/old-database/special-orders/' + cavAppId)
        .then((res) => {
          this.form.fill(res.data);
          this.form.units_earned = this.app.units_earned;
          this.url(this.form.path);
          this.show = false;
        })
        .catch((err) => {
          toastr.error('Failed to fetch special order details.', 'Error');
        });
    },

    save() {
      let b = document.getElementById('edit-unit-earner-btn');
      b.innerHTML = 'Saving...';
      b.disabled = true;

      axios
        .post(
          'api/old-database/special-orders/update-from-for-process',
          this.form
        )
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          this.edit = false;
          b.innerHTML = 'Save';
          b.disabled = false;
        })
        .catch(() => {
          b.innerHTML = 'Save';
          b.disabled = false;
          toastr.error(
            'Oops! Something went wrong. Please contact the administrator',
            'Error'
          );
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    url(path) {
      return (this.src = `https://drive.google.com/file/d/${path}/preview`);
    },
  },
};
</script>
