<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="my-auto">
        <h5 class="font-weight-bold ">
          Certification, Authentication & Verification (CAV) Applications
        </h5>
      </div>

      <div class="form-inline">
        <label class="px-2">Application Daily Limit</label>
        <select
          name=""
          class="form-control"
          id=""
          v-model="dailyLimit"
          :disabled="
            $store.state.user.last_name !== 'Rebita' || dailyLimitLoader
          "
          @change="setDailyLimit"
        >
          <option v-for="value in values" :value="value" :key="value">
            {{ value }}
          </option>
        </select>
        <div v-if="dailyLimitLoader" class="px-2">
          <span class="spinner-border spinner-border-sm mr-1"></span>
        </div>
        <button @click.prevent="openApplicationForm" class="btn btn-dark ml-3">
          <i class="bi bi-person-plus mr-2"></i>Walk-in application
        </button>
      </div>
    </div>

    <div class="card shadow-sm rounded mt-4">
      <div class="card-body">
        <div>
          <!---------------------- Tabs ------------------------------>
          <ul class="nav nav-tabs" id="cav-ul-tabs">
            <li class="nav-item">
              <a
                id="ForPaymentValidation"
                class="nav-link active"
                @click.prevent="tabActive('ForPaymentValidation')"
                href="#"
                >Payment Validation
                <span
                  v-show="stats['FOR PAYMENT VALIDATION'].count"
                  class="badge badge-secondary"
                  >{{ stats['FOR PAYMENT VALIDATION'].count }}</span
                ></a
              >
            </li>
            <li class="nav-item ">
              <a
                id="ForVerification"
                class="nav-link"
                @click.prevent="tabActive('ForVerification')"
                href="#"
                >Records Verification
                <span
                  v-show="stats['FOR VERIFICATION'].count"
                  class="badge badge-secondary"
                  >{{ stats['FOR VERIFICATION'].count }}</span
                ></a
              >
            </li>
            <li class="nav-item ">
              <a
                id="ForProcess"
                class="nav-link"
                @click.prevent="tabActive('ForProcess')"
                href="#"
                >For Process
                <span
                  v-show="stats['FOR PROCESS'].count"
                  class="badge badge-secondary"
                  >{{ stats['FOR PROCESS'].count }}</span
                ></a
              >
            </li>

            <li class="nav-item">
              <a
                id="ForRelease"
                class="nav-link "
                @click.prevent="tabActive('ForRelease')"
                href="#"
                >For Release
                <span
                  v-show="stats['FOR RELEASE'].count"
                  class="badge badge-success"
                  >{{ stats['FOR RELEASE'].count }}</span
                ></a
              >
            </li>

            <li class="nav-item">
              <a
                id="Pending"
                class="nav-link"
                href="#"
                @click.prevent="tabActive('Pending')"
                >Pending
                <span
                  v-show="stats['PENDING'].count"
                  class="badge badge-danger"
                  >{{ stats['PENDING'].count }}</span
                ></a
              >
            </li>

            <li class="nav-item">
              <a
                id="All"
                class="nav-link"
                href="#"
                @click.prevent="tabActive('All')"
                >All Applications
              </a>
            </li>
          </ul>

          <!----------------------- // Tabs -------------------------------->
        </div>
      </div>
    </div>

    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div id="" class="">
          <component
            :show="showComponent"
            :is="currentTab"
            v-on:getStats="getStats"
          ></component>
        </div>
      </div>
    </div>

    <div>
      <ApplicationFormModal ref="ApplicationFormModal"></ApplicationFormModal>
    </div>
  </div>
</template>

<script>
import ForVerification from './components/ForVerification.vue';
import ForProcess from './components/ForProcess.vue';
import ForRelease from './components/ForRelease.vue';
import Pending from './components/Pending.vue';
import ForPaymentValidation from './components/ForPaymentValidation.vue';
import All from './components/All.vue';
import ApplicationFormModal from './components/ApplicationFormModal.vue';

export default {
  name: 'CavApplications',
  components: {
    ForVerification,
    ForProcess,
    ForRelease,
    Pending,
    ForPaymentValidation,
    All,
    ApplicationFormModal,
  },
  data() {
    return {
      stats: {
        'FOR VALIDATION': {
          count: 0,
        },
        'FOR PAYMENT VALIDATION': {
          count: 0,
        },
        'FOR VERIFICATION': {
          count: 0,
        },
        'FOR PROCESS': {
          count: 0,
        },
        'FOR RELEASE': {
          count: 0,
        },
        RELEASED: {
          count: 0,
        },
        PENDING: {
          count: 0,
        },
      },
      showComponent: false,
      currentTab: 'ForPaymentValidation',
      form: new Form({}),
      prefix: 'https://drive.google.com/file/d/',
      dailyLimit: '',
      dailyLimitLoader: false,
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $('#cav-ul-tabs .nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },

    getStats() {
      this.resetStats();

      axios
        .get('api/cav-applications/admin/stats')
        .then((res) => {
          let statsArray = res.data;
          statsArray.forEach((e) => {
            this.stats[e.name].count = e.count;
          });
        })
        .catch((err) => {});
    },

    getDailyLimit(config) {
      this.dailyLimitLoader = config.showLoader;
      axios
        .get('api/cav-application-daily-limit')
        .then((res) => {
          this.dailyLimit = res.data;
          this.dailyLimitLoader = false;
        })
        .catch((err) => {
          this.dailyLimitLoader = false;
        });
    },
    setDailyLimit() {
      this.dailyLimitLoader = true;
      axios
        .put('api/cav-application-daily-limit', {
          new_daily_limit: this.dailyLimit,
        })
        .then((res) => {
          this.dailyLimit = res.data;
          this.dailyLimitLoader = false;
        })
        .catch((error) => {
          this.dailyLimitLoader = false;
          toastr.error(error.response.data.message, 'Something went wrong.');
        });
    },
    resetStats() {
      for (const key in this.stats) {
        this.stats[key].count = 0;
      }
    },
    openApplicationForm() {
      this.$refs.ApplicationFormModal.open();
    },
  },
  computed: {
    values() {
      return Array.from({ length: 100 }, (_, i) => i + 1);
    },
  },
  mounted() {
    this.getStats();
    this.getDailyLimit({ showLoader: true });
  },
};
</script>
