<template>
  <div>
    <div class="modal" data-backdrop="static" id="application-form-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
            <div class="modal-body">
              <div v-if="showMsg" class="alert alert-danger">
                We only accept applications during office hours. (Monday to
                Friday - 8:00 AM to 5:00 PM)
              </div>

              <div v-show="errors.length" class="alert alert-danger">
                <ul>
                  <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div class="container">
                <div class="form-group">
                  <label for="">Institution</label>
                  <select
                    name="school-graduated"
                    class="form-control"
                    v-model="form.school_graduated"
                    @change="getHeiPrograms(form.school_graduated)"
                    :disabled="!heis.length"
                    required
                  >
                    <option value="" selected disabled hidden>Choose</option>
                    <option
                      v-for="hei in heis"
                      :key="hei.code"
                      :value="hei.code"
                      >{{ hei.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group">
                  <label for="">Full Name</label>
                  <div class="input-group">
                    <input
                      name="firstname"
                      v-model="form.firstname"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="First Name"
                      required
                    />
                    <input
                      name="middlename"
                      v-model="form.middlename"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="Middle Name"
                    />
                    <input
                      name="lastname"
                      v-model="form.lastname"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label>Program</label>
                  <select
                    name="program"
                    class="form-control"
                    v-model="form.program_id"
                    id=""
                    :disabled="isLoadingPrograms || !form.school_graduated"
                    required
                  >
                    <option
                      :value="program.program_id"
                      v-for="program in heiPrograms"
                      :key="program.id"
                    >
                      {{ program.program_name }}
                    </option>
                  </select>
                </div>

                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label class="">Student Type</label>
                      <select
                        name="student-type"
                        class="form-control"
                        v-model="form.student_type"
                        id=""
                        required
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >
                        <option value="1">Graduate (GR)</option>
                        <option value="2">Unit Earner (UE)</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Sector</label>

                      <select
                        v-model="form.sector"
                        name="sector"
                        class="form-control"
                        required
                      >
                        <option value="" selected hidden disabled
                          >Choose</option
                        >
                        <option value="1">N/A</option>
                        <option value="2">Indigenous People (IP)</option>
                        <option value="3">Person with Disability (PWD)</option>
                        <option value="4">Senior Citizen (SC)</option>
                        <option value="5">Solo Parent (SP)</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Email (Optional)</label>

                  <input
                    name="email"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                    placeholder="example@gmail.com"
                  />
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Sex</label>
                      <select
                        v-model="form.sex"
                        name="sex"
                        class="form-control"
                        required
                      >
                        <option value="" selected hidden disabled
                          >Choose</option
                        >
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Date of Birth</label>

                      <input
                        name="date"
                        v-model="form.date_of_birth"
                        type="date"
                        class="form-control"
                        placeholder="Enter your age"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Contact Number</label>

                  <input
                    name="contact-no"
                    v-model="form.contact_no"
                    type="text"
                    class="form-control"
                    placeholder="Enter 11-digit mobile number"
                    maxlength="11"
                    required
                  />

                  <small class="form-text text-muted"
                    >SMS confirmation will be sent to this number.
                  </small>
                </div>

                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="form-group">
                        <label>Apply for</label>

                        <select
                          v-model="form.purpose"
                          class="form-control"
                          name="purpose"
                          required
                        >
                          <option value="" selected hidden disabled
                            >Choose</option
                          >
                          <option value="1">Local</option>
                          <option value="2">Abroad</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>CTC (Optional)</label>

                      <input
                        name="ctc-qty"
                        v-model="form.ctc_qty"
                        type="number"
                        class="form-control"
                        placeholder="Leave blank if no CTC"
                      />
                    </div>
                  </div>

                  <div class="form-group" v-if="form.purpose == 2">
                    <label>Country</label>
                    <select
                      name="country-id"
                      class="form-control"
                      v-model="form.country_id"
                      required
                      id=""
                    >
                      <option value="" selected disabled hidden>Choose</option>
                      <option v-for="c in countries" :key="c.id" :value="c.id">
                        {{ c.country_name }}
                      </option>
                    </select>
                    <small class="text-danger mb-2"
                      >Please submit the Hard Copy of the Documents
                      (Original/Aunthenticated - depends of the student) to CHED
                      Caraga for DFA Authentication.</small
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label>Purpose</label>
                  <select
                    name="reason"
                    class="form-control"
                    v-model="form.reason"
                    required
                  >
                    <option value="" selected disabled hidden>Choose</option>
                    <option value="Employment">For Employment</option>
                    <option value="Study/Enrollment"
                      >For Study/Enrollment</option
                    >
                    <option value="Board Examination"
                      >For Board Examination</option
                    >

                    <option value="Promotion">For Promotion</option>
                    <option value="Retirement">For Retirement</option>
                    <option value="6">Others</option>
                  </select>

                  <div class="mt-2" v-if="form.reason == '6'">
                    <input
                      name="others"
                      type="text"
                      class="form-control"
                      placeholder="Pls. specify here"
                      v-model="form.others"
                      required
                    />
                  </div>
                </div>

                <div class="form-group mb-1">
                  <label>Documentary Requirements</label>
                  <div class="input-group">
                    <input
                      id="file-input"
                      type="file"
                      accept="application/pdf"
                      class="form-control-file"
                      @change="handleFileUploadEvent"
                      required
                    />
                  </div>
                  <small class="form-text text-muted"
                    >Make sure to upload in <strong>PDF</strong> format only.
                  </small>
                </div>

                <div class="form-check mt-4">
                  <input
                    name="checkbox"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    v-model="checkbox"
                  />
                  <label class="form-check-label">
                    All entries have been double-checked and confirmed to be
                    correct.
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link"
                @click.prevent="form.reset()"
                data-dismiss="modal"
              >
                CLOSE
              </button>
              <button
                id="submit-btn"
                type="submit"
                class="btn btn-primary"
                :disabled="!checkbox"
                v-show="showApplyBtn"
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationFormModal',
  data() {
    return {
      isLoadingPrograms: false,
      checkbox: false,
      heis: [],
      countries: '',
      errors: [],
      heiPrograms: [],
      showApplyBtn: false,
      showMsg: false,
      form: new Form({
        lastname: '',
        firstname: '',
        middlename: '',
        student_type: '',
        email: '',
        sex: '',
        date_of_birth: '',
        contact_no: '',
        sector: '',
        purpose: '',
        reason: '',
        others: '',
        country_id: '',
        program_id: '',
        ctc_qty: '',
        file: '',
        school_graduated: '',
      }),
    };
  },
  methods: {
    open() {
      $('#application-form-modal').modal('show');
      this.form.reset();
      this.checkbox = false;
      this.getHeis();
      this.getCountries();
      this.checkDateTime();
    },

    handleFileUploadEvent(event) {
      this.form.file = event.target.files[0];
    },

    handleSubmit() {
      let b = document.getElementById('submit-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> SUBMITTING...`;

      let fd = new FormData();

      for (let key in this.form) {
        fd.append(key, this.form[key]);
      }

      axios
        .post('api/cav-applications', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          toastr.success('Application successfully submitted.', 'Information');
          b.innerHTML = 'SUBMIT';
          b.disabled = false;
          this.$emit('getApplications');
          document.getElementById('file-input').value = null;
          $('#application-form-modal').modal('hide');
        })
        .catch((error) => {
          this.errors = error.response.data?.errors?.contact_no;
          b.innerHTML = 'SUBMIT';
          b.disabled = false;
          toastr.error(error.response.data.message, 'Oops!');
        });
    },

    checkDateTime() {
      axios
        .get('api/check-date-time')
        .then((res) => {
          this.showApplyBtn = res.data;
          this.showApplyBtn ? (this.showMsg = false) : (this.showMsg = true);
        })
        .catch(() => {});
    },

    getHeiPrograms(hei_code) {
      this.heiPrograms = [];
      this.isLoadingPrograms = true;

      axios
        .get('api/hei-programs/' + hei_code)
        .then((response) => {
          this.heiPrograms = response.data;
          this.isLoadingPrograms = false;
        })
        .catch((error) => {
          this.isLoadingPrograms = false;
          toastr.error('Something went wrong', 'Request failed');
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          toastr.error('Oops! Something went wrong.', 'Error');
        });
    },
    getCountries() {
      axios
        .get('api/countries/all')
        .then((response) => {
          this.countries = response.data;
        })
        .catch((error) => {
          toastr.error('Oops! Something went wrong.', 'Error');
        });
    },
  },
  mounted() {},
};
</script>
