<template>
  <div class="">
    <div class="row mb-4">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary text-dark" @click="clear">
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            @change="changeInputType"
            v-model="column"
          >
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="created_at">Date Applied</option>
            <option value="abbrev">Institution</option>
          </select>
          <input
            id="search-input"
            type="text"
            class="form-control"
            placeholder="Search"
            v-model="searchValue"
          />
        </div>
      </div>

      <div class="col-auto px-2">
        <button
          @click="
            getForPaymentValidation({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: true },
            })
          "
          class="btn btn-outline-secondary text-dark mr-3"
        >
          <i class="bi bi-arrow-repeat mr-1"></i> Reload
          <div
            v-if="reloadLoader"
            class="spinner-border spinner-border-sm mx-1"
            role="status"
          >
            <span class="invisible">Loading...</span>
          </div>
        </button>

        <button class="btn btn-primary" @click="openAutoValidatePaymentModal">
          Auto Validate Payment
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table
        id="apps-for-payment-validation-table"
        class="table table-sm table-bordered table-hover"
      >
        <thead>
          <th>APP NO</th>
          <th>NAME</th>
          <th>TYPE</th>
          <th>HEI</th>
          <th>PROGRAM</th>

          <th>PURPOSE</th>
          <th>DATE APPLIED</th>
          <th>CTC</th>

          <th>STATUS</th>
          <th>ACTION</th>
        </thead>

        <tbody class="text-uppercase">
          <tr v-show="tableLoader" class="text-center">
            <td colspan="14">
              <div class="spinner-border spinner-border-sm mr-2"></div>
              Loading...
            </td>
          </tr>
          <tr v-if="!filteredApps.length && !tableLoader" class="text-center">
            <td colspan="14">No results found.</td>
          </tr>
          <tr
            v-for="app in filteredApps"
            :key="app.id"
            @click.stop="openAppDetailsModal(app)"
          >
            <th>
              <OverdueIndicator :created_at="app.created_at">
              </OverdueIndicator>
              {{ app.id }}
            </th>

            <td class="text-left">
              {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
            </td>
            <td>{{ app.student_type }}</td>
            <td>{{ app.abbrev }}</td>
            <td width="30%">{{ app.program_name }}</td>
            <td>{{ app.purpose }}</td>
            <td>{{ app.created_at | dateTime }}</td>
            <td class="text-right">{{ app.ctc_qty }}</td>

            <td>
              <span class="badge badge-primary">{{ app.status }}</span>
            </td>
            <td>
              <a @click.stop="deleteApp(app.id, app.lastname)">
                <i class="bi bi-trash-fill text-danger"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="modal fade"
      data-backdrop="static"
      id="auto-validate-payment-modal"
    >
      <div class="modal-dialog" style="max-width: 50%" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Auto Validate Payment</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              :disabled="validationLoader"
              @click="resetAutoValidatePaymentForm"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-warning font-weight-bold">
              <i class="bi bi-exclamation-circle-fill mr-1"></i>To prevent any
              errors, please ensure that you refrain from making any changes to
              the exported report.
            </div>
            <div class="form-group">
              <label for=""
                >Please upload the exported <strong>CSV file</strong> from
                <strong>LinkBiz Portal</strong>.</label
              >
              <input
                id="linkbiz-csv-file-input"
                type="file"
                class="form-control-file"
                @change="handleUploadedCsv"
              />
            </div>

            <div class="mt-4" v-if="csvData.length">
              <table
                class="table table-sm table-bordered"
                style="font-size: 12.5px;"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>CAV APP NO</th>
                    <th>TYPE</th>
                    <th>NAME</th>
                    <th>LBP CONF. NO.</th>
                    <th>LBP REF. NO.</th>
                    <th>AMOUNT</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(app, index) in csvData"
                    :key="app.lbp_reference_number"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ app['field_#1'] }}</td>
                    <td>{{ app['transaction_type'] }}</td>
                    <td>{{ app['field_#2'] }}</td>
                    <td>{{ app['lbp_confirmation_number'] }}</td>
                    <td>{{ app['lbp_reference_number'] }}</td>
                    <td class="text-right">{{ app['transaction_amount'] }}</td>
                    <td>
                      <span v-if="app['payment_status'] == 1">
                        <i class="bi bi-check-circle-fill text-success"></i>
                      </span>
                      <span v-else class="badge badge-pill badge-danger">{{
                        app['payment_status']
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-4" v-if="csvData.length">
              <button
                class="btn btn-primary"
                @click="autoValidatePayment"
                :disabled="validationLoader"
              >
                Validate
              </button>
            </div>

            <div class="mt-3" v-if="validationLoader">
              <div
                class="spinner-border spinner-border-sm mr-1"
                role="status"
              ></div>
              Please do not close this window. Validating....
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppDetailsModal ref="AppDetailsModal" :editable="false"></AppDetailsModal>
  </div>
</template>

<script>
import AppDetailsModal from './AppDetailsModal.vue';
import OverdueIndicator from './OverdueIndicator';
import Papa from 'papaparse';

export default {
  name: 'ForPaymentValidation',
  components: {
    AppDetailsModal,
    OverdueIndicator,
  },
  data() {
    return {
      searchValue: '',
      file: '',
      apps: [],
      currentID: '',
      tableLoader: false,
      reloadLoader: false,
      validationLoader: false,
      column: 'lastname',
      csv: '',
      csvData: [],
      validationErrors: [],
    };
  },
  methods: {
    getForPaymentValidation(config) {
      this.tableLoader = config.tableLoader.enabled;
      this.reloadLoader = config.reloadLoader.enabled;

      axios
        .get('/api/cav-applications/filter/2')
        .then((res) => {
          let sorted = res.data.sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          this.apps = sorted;
          this.tableLoader = false;
          this.reloadLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
          this.reloadLoader = false;
        });
    },
    async autoValidatePayment() {
      this.validationLoader = true;
      let index = 0;
      for (const app of this.csvData) {
        await this.updatePaymentDetails(app, index);
        index++;
      }

      this.getForPaymentValidation({
        tableLoader: { enabled: false },
        reloadLoader: { enabled: false },
      });

      this.validationLoader = false;
    },
    updatePaymentDetails(app, index) {
      return axios
        .post('api/cav-applications/update-payment-status', app)
        .then(() => {
          this.csvData[index].payment_status = 1;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.csvData[index].payment_status = error.response.data.message;
        });
    },
    openAutoValidatePaymentModal() {
      $('#auto-validate-payment-modal').modal('show');
    },

    changeInputType() {
      this.searchValue = '';
      let input = document.getElementById('search-input');
      this.column == 'created_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
    handleUploadedCsv(event) {
      this.csv = event.target.files[0];
      this.parseCsv();
    },
    parseCsv() {
      Papa.parse(this.csv, {
        transformHeader: function(header, index) {
          return header.replace(/\s+/g, '_').toLowerCase();
        },
        complete: (results) => {
          console.log(results);
          if (results.errors.length) {
            this.parseErrors = results.errors;
          }
          this.csvData = results.data.map((obj) => ({
            ...obj,
            payment_status: '',
          }));
        },

        error: function(err, file, inputElem, reason) {
          alert(reason);
        },
        skipEmptyLines: 'greedy',
        header: true,
      });
    },
    clear() {
      this.column = '';
      this.searchValue = '';
    },

    deleteApp(appId, lastname) {
      if (
        confirm(
          `Are you sure you want to permanently delete this application? \nApp No: ${appId} \nLast Name: ${lastname.toUpperCase()}`
        )
      ) {
        axios
          .delete('api/cav-applications/' + appId)
          .then((res) => {
            this.getForPaymentValidation({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: false },
            });
            toastr.success(res.data.msg, 'Information');
          })
          .catch((err) => {
            toastr.error('Something went wrong.', 'Error');
          });
      }
    },

    openAppDetailsModal(app) {
      this.$refs.AppDetailsModal.openModal(app);
    },
    resetAutoValidatePaymentForm() {
      document.getElementById('linkbiz-csv-file-input').value = '';
      this.csv = '';
      this.csvData = [];
    },
  },

  computed: {
    filteredApps() {
      let temp = this.apps;
      let excluded = [
        'Rebita',
        'Caingles',
        'Makidato',
        'Gozon',
        'Canlas',
        'Lajos',
        'Cabang',
        'Japson',
        'Pupos',
      ];

      /* filter by recently updated apps excluding the names
       * ['REBITA', 'CAINGLES', 'MAKIDATO', 'GOZON']
       * added on 6/29/2022
       */
      if (this.column === 'recent') {
        temp = temp.filter((app) => {
          return !excluded.includes(app.updated_by);
        });
      }

      if (this.searchValue != '') {
        temp = temp.filter((cav) => {
          return cav[this.column]
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getForPaymentValidation({
      tableLoader: { enabled: true },
      reloadLoader: { enabled: false },
    });

    this.$emit('getStats');
  },
};
</script>

<style scoped>
#apps-for-payment-validation-table tr {
  cursor: pointer !important;
}
</style>
