<template>
  <div class="">
    <div class="">
      <div class="modal fade" data-backdrop="static" id="advanced-filter-modal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="container py-3">
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="font-weight-bold">
                    <i class="bi bi-funnel-fill mr-1"></i> Advanced Filter
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" style="font-size:24px;"
                      >&times;</span
                    >
                  </button>
                </div>

                <div class="">
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="">Student Type</label>
                        <select
                          v-model="params.student_type"
                          class="form-control"
                          id=""
                        >
                          <option value="" selected>All</option>
                          <option value="GR">Graduate (GR)</option>
                          <option value="UE">Unit Earner (UE)</option>
                        </select>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group">
                        <label for="">Sex</label>
                        <select v-model="params.sex" class="form-control" id="">
                          <option value="" selected>All</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group">
                        <label for="">Purpose</label>
                        <select
                          v-model="params.purpose"
                          class="form-control"
                          id=""
                        >
                          <option value="" selected>All</option>
                          <option value="Local">Local</option>
                          <option value="Abroad">Abroad</option>
                        </select>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group">
                        <label for="">Sector</label>

                        <select
                          name=""
                          class="form-control"
                          id=""
                          v-model="params.sector"
                        >
                          <option value="" selected>All</option>
                          <option value="N/A">N/A</option>
                          <option value="IP">Indigenous People (IP)</option>
                          <option value="PWD"
                            >Person with Disability (PWD)</option
                          >
                          <option value="SC">Senior Citizen (SC)</option>
                          <option value="SP">Solo Parent (SP)</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="">Status</label>
                        <select
                          v-model="params.status_id"
                          class="form-control"
                          id=""
                        >
                          <option value="" selected>All</option>
                          <option value="2">For Payment Validation</option>
                          <option value="3">For Verification</option>
                          <option value="4">For Process</option>
                          <option value="5">For Release</option>
                          <option value="6">Released</option>
                          <option value="7">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="">Reason</label>
                        <select
                          v-model="params.reason"
                          class="form-control"
                          id=""
                        >
                          <option value="" selected>All</option>
                          <option value="Employment">For Employment</option>
                          <option value="Study/Enrollment"
                            >For Study/Enrollment</option
                          >
                          <option value="Board Examination"
                            >For Board Examination</option
                          >

                          <option value="Promotion">For Promotion</option>
                          <option value="Retirement">For Retirement</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Program</label>
                        <select
                          v-model="params.program_id"
                          class="form-control"
                          disabled
                          id=""
                        >
                          <option value="" selected>To be added</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="">Date Applied</label>
                        <div class="input-group">
                          <input
                            v-model="params.date_applied_from"
                            type="date"
                            class="form-control mr-2"
                          />
                          <span class="my-auto">to</span>
                          <input
                            v-model="params.date_applied_to"
                            type="date"
                            class="form-control ml-2"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group">
                        <label for="">Date Released</label>
                        <div class="input-group">
                          <input
                            v-model="params.date_released_from"
                            type="date"
                            class="form-control mr-2"
                          />
                          <span class="my-auto">to</span>
                          <input
                            v-model="params.date_released_to"
                            type="date"
                            class="form-control ml-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="">Released by</label>
                        <select
                          v-model="params.released_by"
                          class="form-control"
                          id=""
                        >
                          <option value="" selected>All</option>
                          <option value="Juezan">Lennie Juezan</option>
                          <option value="Rebita">Mercedes Rebita</option>
                          <option value="Pacot">Ceaka Angel Pacot</option>
                          <option value="Cabang">Misael Cabang</option>
                          <option value="Gozon">Trician Faye Gozon</option>
                          <option value="Caingles">Michelle Caingles</option>
                          <option value="Makidato">Judith Makidato</option>
                        </select>
                      </div>
                    </div>
                    <div class="col"></div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-outline-primary mr-2"
                      @click.prevent="resetAdvancedFilter"
                    >
                      Reset filters
                    </button>
                    <button class="btn btn-primary" @click.prevent="filter">
                      Filter results
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form
        @submit.prevent="
          getAll(DEFAULT_URL, {
            tableLoader: { enabled: false },
            searchLoader: { enabled: true },
          })
        "
      >
        <div class="input-group col-5 px-0">
          <select
            name=""
            id=""
            class="form-control col-auto"
            v-model="params.column"
          >
            <option value="" hidden selected disabled>Choose</option>
            <option value="cad.id">App No</option>
            <option value="cad.lastname">Last Name</option>
            <option value="cad.firstname">First Name</option>
            <option value="cad.middlename">Middle Name</option>
            <option value="cad.school_graduated">HEI Abbrev.</option>
          </select>

          <input
            v-if="
              params.column !== 'cad.school_graduated' &&
                params.column !== 'cad.created_at'
            "
            id="search-input"
            type="search"
            class="form-control"
            placeholder="Search"
            autocomplete="on"
            v-model="params.value"
          />

          <select
            v-if="params.column == 'cad.school_graduated'"
            v-model="params.value"
            name=""
            class="form-control"
            id=""
          >
            <option selected disabled hidden value="">Choose</option>
            <option v-for="hei in heis" :key="hei.code" :value="hei.code">
              {{ hei.abbrev }}</option
            >
          </select>

          <div class="input-group-append">
            <button
              id="cav-app-search-btn"
              type="submit"
              class="btn btn-outline-secondary"
            >
              <span v-if="!searchLoader">
                <i class="bi bi-search text-dark"></i
              ></span>
              <span v-else class="spinner-border spinner-border-sm"></span>
            </button>
          </div>

          <button
            type="button"
            class="btn btn-outline-secondary text-dark ml-3 "
            @click.prevent="openAdvancedFilterModal"
          >
            <i class="bi bi-funnel-fill mr-1"></i> Advanced Filter
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary text-dark ml-3 "
            @click.prevent="exportToCSV"
          >
            <i class="bi bi-filetype-csv mr-2"></i>Export to CSV
          </button>
        </div>
      </form>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div class="form-inline">
        <div class="form-group">
          <label for="">No. of rows</label>
          <select
            name=""
            id=""
            class="form-control form-control-sm ml-2"
            v-model="params.per_page"
            @change="
              getAll(DEFAULT_URL, {
                tableLoader: { enabled: false },
                searchLoader: { enabled: false },
              })
            "
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
      <div class="d-flex justify-content-right align-items-baseline">
        <div class="my-auto mr-2">
          <span
            >{{
              `${pagination.from || 0}-${pagination.to ||
                0} of ${pagination.total || 0}`
            }}
          </span>
        </div>
        <nav>
          <ul class="pagination mb-0 pagination-sm">
            <li
              class="page-item"
              :class="{ disabled: !pagination.prev_page_url }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="
                  getAll(pagination.prev_page_url, {
                    tableLoader: { enabled: false },
                    searchLoader: { enabled: false },
                  })
                "
              >
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-left"></i
                ></span>
                <span class="sr-only">Previous</span>
              </a>
            </li>

            <li
              class="page-item"
              :class="{ disabled: !pagination.next_page_url }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="
                  getAll(pagination.next_page_url, {
                    tableLoader: { enabled: false },
                    searchLoader: { enabled: false },
                  })
                "
              >
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-right"></i
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="table-responsive">
      <table
        id="apps-all-table"
        class="table table-sm table-bordered table-hover mt-3"
      >
        <thead>
          <th>APP NO</th>
          <th>NAME</th>
          <th>TYPE</th>
          <th>HEI</th>
          <th width="30%">PROGRAM</th>
          <th>PURPOSE</th>
          <th>DATE APPLIED</th>

          <th>RELEASED TO</th>
          <th>STATUS</th>
        </thead>

        <tbody class="text-uppercase">
          <tr v-show="tableLoader" class="text-center">
            <td colspan="15">
              <div class="spinner-border spinner-border-sm mr-2"></div>
              Loading...
            </td>
          </tr>
          <tr v-if="!apps.length && !tableLoader" class="text-center">
            <td colspan="15">No results found.</td>
          </tr>
          <tr
            v-for="app in apps"
            :key="app.id"
            @click.stop="openAppDetailsModal(app)"
          >
            <th>{{ app.id }}</th>

            <td class="text-left">
              {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
            </td>
            <td>{{ app.student_type }}</td>
            <td>{{ app.abbrev }}</td>
            <td>{{ app.program_name }}</td>
            <td>{{ app.purpose }}</td>
            <td>{{ app.created_at | dateTime }}</td>

            <td>
              <span v-show="app.received_by">{{ app.received_by }} </span>
            </td>
            <td>
              <span :class="textClass(app.status_id)">{{ app.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <AppDetailsModal ref="AppDetailsModal" :editable="false"></AppDetailsModal>
  </div>
</template>

<script>
import Papa from 'papaparse';
import AppDetailsModal from './AppDetailsModal.vue';
export default {
  name: 'AllTransactions',
  components: {
    AppDetailsModal,
  },
  data() {
    return {
      count: '...',
      file: '',
      apps: [],
      heis: [],
      currentID: '',
      tableLoader: false,
      searchLoader: false,
      pagination: {},

      DEFAULT_URL: '/api/all-transactions',
      params: {
        value: '',
        column: 'cad.lastname',
        student_type: '',
        sex: '',
        purpose: '',
        reason: '',
        sector: '',
        status_id: '',
        program_id: '',
        date_applied_from: '',
        date_applied_to: '',
        date_released_from: '',
        date_released_to: '',
        released_by: '',
        per_page: 10,
      },
    };
  },
  methods: {
    exportToCSV() {
      const csv = Papa.unparse(this.apps);
      this.downloadCSV(csv, 'cav_applications_data.csv');
    },
    downloadCSV(csv, filename) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        // Other browsers
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    resetAdvancedFilter() {
      const currentValue = this.params.value;
      const currentColumn = this.params.column;

      // Reset all properties in params to default values
      for (const key in this.params) {
        if (key !== 'value' && key !== 'column') {
          this.params[key] = '';
        }
      }
      this.params.value = currentValue;
      this.params.column = currentColumn;
      this.params.per_page = 10;
    },
    openAdvancedFilterModal() {
      $('#advanced-filter-modal').modal('show');
    },

    filter() {
      $('#advanced-filter-modal').modal('hide');
      this.apps = [];
      this.getAll(this.DEFAULT_URL, {
        tableLoader: { enabled: true },
        searchLoader: { enabled: false },
      });
    },

    getAll(page_url, options) {
      this.tableLoader = options.tableLoader.enabled;
      this.searchLoader = options.searchLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      axios
        .get(page_url, {
          params: this.params,
        })
        .then((res) => {
          this.apps = res.data.data;
          this.makePagination(res.data);
          this.tableLoader = false;
          this.searchLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
          this.searchLoader = false;
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          toastr.error('Oops! Something went wrong.', 'Error');
        });
    },

    openAppDetailsModal(data) {
      this.$refs.AppDetailsModal.openModal(data);
    },

    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },

    textClass(status) {
      switch (status) {
        case 1:
          return 'badge badge-secondary';
        case 2:
          return 'badge badge-secondary';
        case 3:
          return 'badge badge-secondary';
        case 4:
          return 'badge badge-secondary';
        case 5:
          return 'badge badge-secondary';
        case 6:
          return 'badge badge-success';
        case 7:
          return 'badge badge-danger';
        case 8:
          return 'badge badge-danger';
      }
    },
  },

  mounted() {
    this.getAll(this.DEFAULT_URL, {
      tableLoader: { enabled: true },
      searchLoader: { enabled: false },
    });

    this.getHeis();
  },
};
</script>

<style scoped>
#apps-all-table tr {
  cursor: pointer !important;
}
</style>
