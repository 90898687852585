<template>
  <div>
    <div class="modal fade" id="generate-cav-modal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="container pt-3">
            <form @submit.prevent="generateCAV">
              <div class="modal-body">
                <p class="mb-2">
                  {{ Date() | standardDate }}
                </p>
                <strong>CAV (CHED-CARAGA) NO. {{ app.control_no }}</strong>
                <p>Series of {{ new Date().getFullYear() }}</p>

                <table
                  class="table table-bordered table-sm mt-1 text-uppercase"
                >
                  <tbody>
                    <tr>
                      <td width="25%">App No</td>
                      <th>
                        {{ app.id }}
                      </th>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <th>
                        {{ so.firstName }} {{ so.middleName }} {{ so.lastName }}
                      </th>
                    </tr>
                    <tr>
                      <td>SECPA No</td>
                      <th>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.secpa_no"
                          :disabled="app.purpose == 'Local' ? true : false"
                          required
                        />
                      </th>
                    </tr>

                    <tr>
                      <td>LBP CN/OR NO.</td>
                      <th>
                        {{ app.receipt_no }} ({{
                          app.receipt_date | shortDate
                        }})
                      </th>
                    </tr>

                    <tr>
                      <td>Degree</td>
                      <th>
                        {{ so.progName }}
                      </th>
                    </tr>

                    <tr>
                      <td>Date Started</td>
                      <th>{{ so.semEnrolled }} (AY {{ so.acadYear }})</th>
                    </tr>
                    <tr>
                      <td>Date Ended</td>
                      <th>{{ so.semStarted }} (AY {{ so.semYear }})</th>
                    </tr>

                    <tr>
                      <td>Grad. Date</td>
                      <th>{{ so.gradDate | standardDate }}</th>
                    </tr>

                    <tr>
                      <td>Special Order</td>
                      <th>
                        {{ so.soLevel }} {{ so.soRegion }} {{ so.soNumber }}
                        {{ so.soSequence }} {{ so.soSeries }}
                      </th>
                    </tr>
                    <tr>
                      <td>Mode of Study</td>
                      <th>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          v-model="form.study_mode"
                          required
                        >
                          <option value="CONVENTIONAL" selected
                            >CONVENTIONAL</option
                          >
                          <option value="CONVENTIONAL AND BLENDED"
                            >CONVENTIONAL AND BLENDED</option
                          >

                          <option value="CONVENTIONAL AND ONLINE"
                            >CONVENTIONAL AND ONLINE</option
                          >
                          <option value="CONVENTIONAL AND OFFLINE"
                            >CONVENTIONAL AND OFFLINE</option
                          >
                          <option value="CONVENTIONAL AND ETEEAP"
                            >CONVENTIONAL AND ETEEAP</option
                          >
                          <option value="ONLINE AND BLENDED"
                            >ONLINE AND BLENDED</option
                          >
                          <option value="OFFLINE AND BLENDED"
                            >OFFLINE AND BLENDED</option
                          >
                          <option value="BLENDED">BLENDED</option>
                          <option value="ONLINE">ONLINE</option>
                          <option value="OFFLINE">OFFLINE</option>

                          <option value="NON-CONVENTIONAL"
                            >NON-CONVENTIONAL</option
                          >
                          <option value="ETEEAP">ETEEAP</option>
                          <option value="ETEEAP AND ONLINE"
                            >ETEEAP AND ONLINE</option
                          >
                        </select>
                      </th>
                    </tr>
                    <tr>
                      <td>Institution</td>
                      <th>{{ so.schoolName }}</th>
                    </tr>

                    <tr>
                      <td>Address</td>
                      <th>{{ so.schoolAddress }}</th>
                    </tr>

                    <tr>
                      <td>Reviewed by</td>
                      <th>
                        <select
                          name=""
                          id=""
                          class="form-control "
                          v-model="form.reviewed_by"
                          required
                        >
                          <option value="Francis D. Ilagan"
                            >FRANCIS D. ILAGAN</option
                          >
                          <option value="Misael S. Cabang"
                            >MISAEL S. CABANG</option
                          >
                          <option value="Revelyn P. Brina"
                            >REVELYN P. BRINA</option
                          >
                          <option value="Angel A. Asis">ANGEL A. ASIS</option>

                          <option value="Geselle M. Francisco"
                            >GESELLE M. FRANCISCO</option
                          >
                          <option value="Haemaccel I. Palima"
                            >HAEMACCEL I. PALIMA</option
                          >
                          <option value="Ma. Mercedes P. Rebita"
                            >MA. MERCEDES P. REBITA</option
                          >
                        </select>
                      </th>
                    </tr>
                    <tr>
                      <td>Signed by</td>
                      <th>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          v-model="form.signed_by"
                          required
                        >
                          <option
                            value="GEORGE M. COLORADO, PhD, CESO III : Director IV"
                            selected
                            >GEORGE M. COLORADO, PhD, CESO III : Director
                            IV</option
                          >
                          <option
                            value="ALMA SY-PATRON, PHD, RSW : OIC, Office of the Regional Director"
                            >ALMA SY-PATRON, PHD, RSW : OIC, Office of the
                            Regional Director</option
                          >
                          <option
                            value="JULIA FELISA C. MARTINEZ, PhD,LPT : Chief Education Program Specialist"
                            >JULIA FELISA C. MARTINEZ, PhD,LPT : Chief Education
                            Program Specialist</option
                          >
                          <option
                            value="NOEME ROSE D. MANI, MM, CPA : SEPS/OIC, Chief Administrative Officer"
                            >NOEME ROSE D. MANI, MM, CPA : SEPS/OIC, Chief
                            Administrative Officer</option
                          >
                          <option
                            value="REVELYN P. BRINA, MAED : Administrative Officer III"
                            >REVELYN P. BRINA, MAED : Administrative Officer
                            III</option
                          >
                          <option
                            value="GESELLE M. FRANCISCO, CPA : OIC, Chief Administrative Officer"
                            >GESELLE M. FRANCISCO, CPA : OIC, Chief
                            Administrative Officer</option
                          >
                          <option
                            value="AMY C. SAAVEDRA, MSCA : Education Supervisor II"
                            >AMY C. SAAVEDRA, MSCA : Education Supervisor
                            II</option
                          >
                        </select>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <p class="mb-0">Date & Time: {{ Date() | dateTime }}</p>
                <p class="">
                  Processed by: {{ $store.state.user.first_name }}
                  {{ $store.state.user.last_name }}
                </p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link" data-dismiss="modal">
                  Close
                </button>
                <button
                  id="generate-cav-btn"
                  type="submit"
                  class="btn btn-primary"
                >
                  Generate CAV
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      so: '',
      app: '',
      form: new Form({
        secpa_no: '',
        study_mode: 'CONVENTIONAL',
        reviewed_by: 'Misael S. Cabang',
        signed_by:
          'GESELLE M. FRANCISCO, CPA : OIC, Chief Administrative Officer',
      }),
    };
  },
  methods: {
    openModal(so, app) {
      this.secpa_no = '';
      this.so = so;
      this.app = app;
      if (this.app.purpose == 'Local') {
        this.form.secpa_no = `${this.app.control_no.substring(
          2
        )}-${new Date().getFullYear()}`;
      }

      $('#generate-cav-modal').modal('show');
    },
    generateCAV() {
      let btn = document.getElementById('generate-cav-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Generating...`;
      btn.disabled = true;

      axios
        .post('api/cavs', {
          app: this.app,
          so: this.so,
          form: this.form,
        })
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'Generate CAV';
          this.$emit('getApps');
          this.$emit('print', res.data);
          $('#generate-cav-modal').modal('hide');
          toastr.success(
            `Generated successfully.            
             Name: ${res.data.lastname} 
            `,
            `(CAV ID: ${res.data.id})`
          );
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = 'Generate CAV';
          toastr.error('Something went wrong', 'Error');
        });
    },
  },
};
</script>
