<template>
  <div class="">
    <div class="row mb-4">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary text-dark" @click="clear">
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            @change="changeInputType"
            v-model="column"
          >
            <option value="payment_updated_at">Date Forwarded to RV</option>
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>

            <option value="abbrev">Institution</option>
          </select>
          <input
            id="search-input"
            type="date"
            class="form-control"
            placeholder="Search"
            v-model="searchValue"
          />
        </div>
      </div>

      <div class="col-auto px-2">
        <button
          @click="
            getForVerification({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: true },
            })
          "
          class="btn btn-outline-secondary text-dark"
        >
          <i class="bi bi-arrow-repeat mr-1"></i> Reload
          <div
            v-if="reloadLoader"
            class="spinner-border spinner-border-sm mx-1"
            role="status"
          >
            <span class="invisible">Loading...</span>
          </div>
        </button>
      </div>

      <div class="col-auto px-2">
        <ActionsDropdown
          :selectedApps="selectedApps"
          @updateTable="
            getForVerification({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: false },
            })
          "
          :statusId="3"
        ></ActionsDropdown>
      </div>
    </div>
    <table
      id="apps-for-verification-table"
      class="table table-sm table-bordered table-hover"
    >
      <thead>
        <th>
          <input
            type="checkbox"
            v-model="checked"
            class="large-checkbox"
            @change="checked ? selectAll() : unselectAll()"
          />
        </th>
        <th>APP NO</th>
        <th>NAME</th>
        <th>TYPE</th>
        <th>HEI</th>

        <th>PROGRAM</th>
        <th>PURPOSE</th>
        <th>DATE APPLIED</th>
        <th>CTC</th>

        <th>STATUS</th>
      </thead>

      <tbody class="text-uppercase">
        <tr v-show="tableLoader" class="text-center">
          <td colspan="15">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            Loading...
          </td>
        </tr>
        <tr v-if="!filteredApps.length && !tableLoader" class="text-center">
          <td colspan="15">No results found.</td>
        </tr>
        <tr
          v-for="app in filteredApps"
          :key="app.id"
          @click.prevent="openAppDetailsModal(app)"
          :class="{
            'table-primary font-weight-bold': selectedApps.some(
              (obj) => obj.id == app.id
            ),
          }"
        >
          <td>
            <input
              type="checkbox"
              class="large-checkbox"
              v-model="selectedApps"
              :value="app"
              @click.stop
            />
          </td>
          <td>
            <OverdueIndicator :created_at="app.created_at"> </OverdueIndicator>
            {{ app.id }}
          </td>

          <td class="text-left">
            <a
              href="#"
              class=""
              @click.stop="openVerifySpecialOrderModal(app)"
              disabled
            >
              {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
            </a>
          </td>
          <td>{{ app.student_type }}</td>
          <td>{{ app.abbrev }}</td>

          <td width="30%">{{ app.program_name }}</td>
          <td>{{ app.purpose }}</td>
          <td>{{ app.created_at | dateTime }}</td>
          <td class="text-right">{{ app.ctc_qty }}</td>

          <td>
            <span class="badge badge-warning">{{ app.status }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <AppDetailsModal
      ref="AppDetailsModal"
      @updateTable="
        getForVerification({
          tableLoader: { enabled: false },
          reloadLoader: { enabled: false },
        })
      "
      :editable="true"
    ></AppDetailsModal>

    <VerifySpecialOrder
      ref="VerifySpecialOrderModal"
      @updateTable="
        getForVerification({
          tableLoader: { enabled: false },
          reloadLoader: { enabled: false },
        })
      "
    >
    </VerifySpecialOrder>
  </div>
</template>

<script>
import AppDetailsModal from './AppDetailsModal.vue';
import VerifySpecialOrder from './VerifySpecialOrder.vue';
import ActionsDropdown from './ActionsDropdown.vue';
import OverdueIndicator from './OverdueIndicator.vue';

export default {
  name: 'ForVerification',
  components: {
    AppDetailsModal,
    VerifySpecialOrder,
    ActionsDropdown,
    OverdueIndicator,
  },
  data() {
    return {
      column: 'payment_updated_at',
      searchValue: '',
      selectedApps: [],
      checked: false,
      file: '',
      apps: [],
      currentID: '',
      tableLoader: false,
      reloadLoader: false,
    };
  },
  methods: {
    getForVerification(config) {
      this.tableLoader = config.tableLoader.enabled;
      this.reloadLoader = config.reloadLoader.enabled;

      axios
        .get('/api/cav-applications/filter/3')
        .then((res) => {
          this.apps = res.data;
          this.tableLoader = false;
          this.reloadLoader = false;
          this.selectedApps = [];
        })
        .catch((err) => {
          this.tableLoader = false;
          this.reloadLoader = false;
        });
    },

    changeInputType() {
      this.searchValue = '';
      let input = document.getElementById('search-input');
      this.column == 'payment_updated_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },

    openAppDetailsModal(data) {
      this.$refs.AppDetailsModal.openModal(data);
    },

    openVerifySpecialOrderModal(app, index) {
      this.$refs.VerifySpecialOrderModal.openModal(app, index);
    },

    clear() {
      this.column = '';
      this.searchValue = '';
    },

    selectAll() {
      this.selectedApps = [];
      this.filteredApps.forEach((student) => {
        this.selectedApps.push(student);
      });
    },

    unselectAll() {
      this.selectedApps = [];
    },
  },

  computed: {
    filteredApps() {
      let temp = this.apps;

      temp.sort((a, b) => {
        return new Date(a.payment_updated_at) - new Date(b.payment_updated_at);
      });

      if (this.searchValue != '') {
        temp = temp.filter((cav) => {
          return cav[this.column]
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getForVerification({
      tableLoader: { enabled: true },
      reloadLoader: { enabled: false },
    });

    this.$emit('getStats');
  },
};
</script>

<style scoped>
#apps-for-verification-table tr {
  cursor: pointer !important;
}
</style>
